import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'dayjs/locale/en'
import 'dayjs/locale/ko'
import { lazy } from 'react'

import GoogleTagManager from './components/google-tag-manager'
import './lib/i18n'

import './styles/_index.scss'

const DefaultRoutes = lazy(() => import('./routes'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleTagManager />
      <DefaultRoutes />
    </QueryClientProvider>
  )
}

export default App
