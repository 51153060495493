import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

interface GTMConfig {
  [key: string]: {
    condition: (url: string) => boolean
    gtmId: string
  }
}

export const gtmConfigs: GTMConfig = {
  be: {
    condition: (url) => url.includes('booking-engine'),
    gtmId: 'GTM-5NXX5BNG',
  },
  ws: {
    condition: (url) => !url.includes('sota') && /wassong|whatsong/.test(url),
    gtmId: 'GTM-M97L65JB',
  },
}

const GoogleTagManager = () => {
  useEffect(() => {
    const initializeGTM = () => {
      const currentUrl = window.location.href
      for (const config of Object.values(gtmConfigs)) {
        if (config.condition(currentUrl)) {
          TagManager.initialize({ gtmId: config.gtmId })
          break
        }
      }
    }

    initializeGTM()
  }, [])

  return <></>
}

export default GoogleTagManager
